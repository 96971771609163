@import "src/constants/styles";

.header {
  display: flex;
  align-items: center;

  width: 100%;
  height: 94px;
  background-color: #ffffff;

  .logo {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .logoLink {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      margin-left: 46px;
    }

    .icon {
      width: 45px;
      height: 45px;
      background-image: url('../../assets/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .name {
      font-family: Freigeist;
      font-style: normal;
      font-weight: 550;
      font-size: 22px;
      line-height: 23px;
      color: #1C1C1C;
    }
  }

  .items {
    margin-right: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      margin-right: 46px;
    }

    .headerLanguageSelector {
      display: none;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $tablets-device) {
        display: flex;
      }
    }
  }
}

.buildNumber {
  width: 60px;
  margin-left: 10px;

  a {
    font-size: 10px;
    color: #999999;
    text-decoration: none;
  }
}