.modal {
  outline: none!important;
  z-index: 99999999999;
  background-color: white;
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background-color: #90b8f9;
    margin-bottom: 10px;

    h2 {
      font-size: 20px;
      line-height: 10px;
      color: #545353;
      padding-left: 20px;
    }

    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 20px;
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  .modalFooter {
    display: flex;
    width: 100%;
    background-color: #90b8f9;
    margin-top: 10px;
  }
}