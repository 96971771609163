.galleryUploader {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: darkgrey 1px solid;

  .imagesAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    padding-top: 26px;
    width: 100%;
    height: 60px;
  }
}