@import "src/constants/styles";

.userLogin {

    .loginButton {
      font-size: 12px;
    }

    .userBlock {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: auto;
    height: 100%;
    padding: 0px 4px;

    span {
      display: flex;
      font-size: 14px;
      margin-right: 4px;
    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: auto;
      min-width: 180px;
      height: 100%;
      padding-left: 10px;
    }
  }

  details {
    position: relative;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  details[open] {
    z-index: 10000000;

    .burgerButton {
      div {
        background-color: white;
        transition: all  0.4s ease;
      }

      div:before {
        top: 0;
        transform: rotate(45deg);
        transition: all  0.4s ease;
      }
      div:after {
        top: 0;
        transform: rotate(135deg);
        transition: all  0.4s ease;
      }
    }
  }

  details[open] {
    summary.openState {
      border-radius: 27px 27px 0 0;
      border-bottom: none;
    }

    summary .burgerButton {
      color: red;
    }
  }

  summary {
    display: flex;
    height: 54px;
    cursor: pointer;
    list-style: none;
    border-radius: 27px;
    border: none;
    background-color: #FFFFFF;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      border: 1px solid #BDBDBD;
    }

    font-family: Freigeist;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
  }

  summary:focus {
    outline: none;
  }

  .burgerButton {
    div {
      top: 25px;
      position: relative;
      background: #092d66;
      height: 5px;
      width: 40px;
      border-radius: 2px;
      transition: all  0.4s ease;
    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      div {
        display: none;
      }
    }

    div:before,
    div:after {
      content: '';
      position: absolute;
      top: -13px;
      background: #092d66;
      width: 40px;
      height: 5px;
      border-radius: 2px;
      transition: all  0.4s ease;
    }
    div:after {
      top: 13px;
    }
  }

  .userMenuBlock {
    background-color: white;
    position: fixed;
    top: 94px;
    left: 0px;
    width: 100%;
    height: 100%;

    .userInfoBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: white;

      .userName {
        padding: 5px;
      }

    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      background-color: #0d0ab8;
      position: absolute;
      top: 54px;
      width: 100%;
      height: auto;

      .userInfoBlock {
        display: none;
      }
    }

    ul {
      user-select: none;
      width: 100%;
      padding: 0;
      position: absolute;
      left: 0;
      margin: 0;
      box-sizing: border-box;
      max-height: 200px;
      overflow-y: auto;
      background-color: #FFFFFF;
      border: 1px solid #BDBDBD;

      font-family: Freigeist;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;

      &.openState {
        border-radius: 0 0 10px 10px;
      }

      li {
        margin: 0;
        padding: 14px 0 14px 0px;
        border-bottom: 1px solid #CCC;
        cursor: pointer;
        list-style: none;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

        a {
          text-decoration: none;
          color: black;
        }

        &::first-letter {
          text-transform: capitalize;
        }

        &:hover {
          background-color: #90B8F9;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}