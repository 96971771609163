.currentLanguage {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 5px #2B33EF;
    transition: .5s;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.languageList {
  padding: 0px;
  list-style: none;
  margin: 0px;

  .languageItem {
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      background-color: #2B33EF;
      color: white;
      border-radius: 10px;
    }

    &.selected {
      background-color: #b0b3f5;
      color: white;
      border-radius: 10px;
    }

    img {
      width: 30px;
      height: 30px;
      margin: 10px;
    }
  }

}