.createExcursionPage {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  flex-direction: column-reverse;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    flex-direction: row;
    overflow-y: hidden;
  }

  .excursionForm {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: #FFFFFF;

    .formField {
      width: 100%;
      margin: 0 10px 0 10px;

      &.buttons {
        margin-bottom: 10px;
      }
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      width: 60%;
      padding: 0px 26px;
      flex-grow: 1;
      overflow-y: auto;

      .formField {
        width: calc(33.3% - 5px);
        margin: 0 5px 0 0;
      }
    }

    .fieldWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0 10px 0;
    }

    h1 {
      font-family: Freigeist;
      font-style: normal;
      font-weight: 550;
      font-size: 26px;
      line-height: 27px;
      margin: 0;
    }

    p {
      font-family: Freigeist;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0 10px 0;
    }

    .sliderWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      width: 40%;
    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
  }
}