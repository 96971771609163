.selectWrapper {
  details {
    position: relative;
    width: 100%;
    margin-right: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  details[open] {
    z-index: 100000;
  }

  details[open] {
    summary.bottomPosition {
      border-radius: 26px 26px 0 0;
      border-bottom: none;
    }

    summary.topPosition {
      border-radius: 0 0 26px 26px;
      border-top: none;
    }

  }

  summary {
    display: flex;
    height: 54px;
    cursor: pointer;
    list-style: none;
    border-radius: 26px;
    border: 1px solid #BDBDBD;
    background-color: #FFFFFF;
    padding: 0px 18px;

    font-family: Freigeist;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;

    .inputWrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
    }

    .inputIcons {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-right: 26px;

      :not(:last-child) {
        margin-right: 10px;
      }
    }

    .inputCaption {
      margin-top: 10px;
      font-family: Freigeist;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      max-height: 12px;
      overflow-y: hidden;
      color: rgba(28, 28, 28, 0.66);
      z-index: 2;
    }

    input {
      display: flex;
      vertical-align: bottom;
      border: 1px solid transparent;
      width: 90%;
      height: 70%;
      position: absolute;
      top: 0;
      background: transparent !important;
      outline: none;
      color: #1C1C1C;
      font-family: Freigeist;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      padding-top: 15px;
    }

    .inputIcon {
      font-size: 22px;

      &.action {
        cursor: pointer;
      }

      &.action:hover {
        color: #2B33EF;
      }
    }

    &.secondary {
      background-color: #FFFFFF;
      border-color: #BDBDBD;
      color: #1C1C1C;
    }

    &.main {
      background-color: #FFFFFF;
      border-color: #BDBDBD;
      color: #1C1C1C;
    }

    &.inputFocus {
      border-color: #2B33EF;
    }

    &.inputError {
      border-color: #d73838;

      .inputCaption {
        color: #d73838
      }
    }

  }

  summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
  }

  summary:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 22px;
    top: 16px;
    float: right;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
    border-bottom-left-radius: 2px;
    transform: rotate(45deg) translate(50%, 0%);
    transform-origin: center center;
    transition: transform ease-in-out 100ms;
  }

  summary:focus {
    outline: none;
  }

  details[open] summary:after {
    transform: rotate(-45deg) translate(0%, 0%);
  }

  ul {
    user-select: none;
    width: 100%;
    padding: 0;
    position: absolute;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    max-height: 200px;
    overflow-y: auto;
    background-color: #FFFFFF;
    border: 1px solid #BDBDBD;

    font-family: Freigeist;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    &.bottomPosition {
      top: 54px;
      border-radius: 0 0 10px 10px;
    }

    &.topPosition {
      bottom: 54px;
      border-radius: 10px 10px 0 0;
    }

    li {
      margin: 0;
      padding: 14px 0 14px 10px;
      border-bottom: 1px solid #CCC;
      cursor: pointer;
      list-style: none;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::first-letter {
        text-transform: capitalize;
      }

      &:hover {
        background-color: #90B8F9;
      }

      &:first-child {
        margin-top: 5px;
      }

      &:last-child {
        margin-bottom: 5px;
        border-bottom: none;
      }
    }
  }
}