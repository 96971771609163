.placesItem {
  font-family: Freigeist;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  min-height: 180px;
  max-height: 180px;
  border-bottom: 1px solid #BDBDBD;
  padding: 0px 26px;

  cursor: pointer;

  .dataWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    margin: 0px;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      margin-left: 20px;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
  }

  .gallery {
    display: none;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      display: flex;
      width: 300px;
      height: 150px;
      background-color: #999999;
      margin: auto;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
  }

  &:hover {
    background-color: rgba(82, 160, 227, .2);
  }

  .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &.mainInfo {
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      color: #1C1C1C;
    }

    &.guidInfo {
      color: #1C1C1C;
    }

    &.ticketsInfo {
      color: #2B33EF;
    }

    &.buyInfo {
      font-size: 22px;
      line-height: 23px;
      color: #1C1C1C;
    }

  }
}