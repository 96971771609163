@import "src/constants/styles";

.avatarWrapper {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: fit-content;

  @media (min-width: $tablets-device) {
    &:hover {
      .selectPhoto {
        font-size: 200%;
      }
    }
  }

  .avatar {
    display: flex;
    object-fit: cover;
  }

  .selectPhoto {
    opacity: 1;
    transition: .5s;
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
    font-size: 150%;
    cursor: pointer;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      opacity: 0;
    }

    &:hover {
      font-size: 200%;
    }
  }
}