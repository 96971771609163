.editPopup {
  //background-color: #90B8F9;
  display: flex;
  justify-content: space-around;
  width: 150px;
  height: 40px;
  cursor: pointer;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: #FFFFFF 1px solid;
    border-radius: 50%;

    &:hover {
      box-shadow: 0px 0px 10px darkgrey;
    }

    &.delete {
      background-color: red;
    }
  }
}