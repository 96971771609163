.input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  height: 54px;
  max-height: 54px;
  padding: 0px 18px;
  border-radius: 26px;
  border: 1px solid;
  margin: 10px 0px;

  font-family: Freigeist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;

  .inputWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .inputIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    :not(:last-child) {
      margin-right: 10px;
    }
  }

  .inputCaption {
    margin-top: 10px;
    font-family: Freigeist;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    max-height: 12px;
    overflow-y: hidden;
    color: rgba(28, 28, 28, 0.66);
    z-index: 2;
  }

  input {
    display: flex;
    vertical-align: bottom;
    border: 1px solid transparent;
    width: 100%;
    height: 70%;
    position: absolute;
    top: 0;
    background: transparent !important;
    outline: none;
    color: #1C1C1C;
    font-family: Freigeist;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    padding-top: 15px;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;
    height: 90%;
    -webkit-appearance: none;
  }

  &.secondary {
    background-color: #FFFFFF;
    border-color: #BDBDBD;
    color: #1C1C1C;
  }

  &.main {
    background-color: #FFFFFF;
    border-color: #BDBDBD;
    color: #1C1C1C;
  }

  &.inputFocus {
    border-color: #2B33EF;
  }

  &.inputError {
    border-color: #d73838;

    .inputCaption {
      color: #d73838
    }
  }

  .inputIcon {
    font-size: 22px;

    &.action {
      cursor: pointer;
    }

    &.action:hover {
      color: #2B33EF;
    }
  }

}