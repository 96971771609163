.enterSMSForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  font-family: Freigeist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;

  .description {
    width: 80%;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 50px;
    color: #2B33EF;
  }
}