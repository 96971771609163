.roundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  border: 1px solid;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.secondary {
    background-color: #FFFFFF;
    border-color: #BDBDBD;
    color: #1C1C1C;
  }

  &.main {
    background-color: #2B33EF;
    border-color: #2B33EF;
    color: #FFFFFF;
  }

  &.error {
    background-color: #F12121;
    border-color: #F12121;
    color: #FFFFFF;
  }

  &.disabled {
    color: #BDBDBD;
    background-color: #e5e4e4;
    border-color: #BDBDBD;
    cursor: not-allowed;
  }

  &:hover {
    filter: saturate(130%);
  }

}