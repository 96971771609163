@import "src/constants/styles";

.loginPageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;

  .loginForm {
    min-height: 300px;
    width: 100%;
    height: calc(100% - 52px);
    overflow: hidden;
    margin: 26px auto 0px auto;

    border-radius: 16px;
    background-color: #ffffff;
    color: #1C1C1C;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $tablets-device) {
      width: 35%;
    }

    .selectAction {
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 60px;
      align-items: center;
      justify-content: center;
      color: #2B33EF
    }

    .caption {
      font-family: Freigeist, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 23px;
      color: rgba(28, 28, 28, 0.66);
      text-align: center;
    }

    .form {
      display: flex;
      padding: 10px 26px;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
    }
  }
}