@import "src/constants/styles";

.mobileUserCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #1a6cef;
  padding: 16px 0px;
}

.mobileUserCardLanguageSelector {
  position: absolute;
  right: 20px;
  bottom: 16px;
}