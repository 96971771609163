.map {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: cell!important;

  .leaflet-control-container {
    display: none;
  }


  .marker-cluster {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px #90B8F9 solid;
    background-color: #448AFFC0;
    color: white;
    transition: .3s;

    &:hover {
      background-color: #448AFF;
      width: 70px;
      height: 70px;
      transition: .3s;
    }
  }

}