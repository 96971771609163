@font-face {
  font-family: Freigeist;
  src: url('./assets/fonts/Freigeist-Regular.woff');
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Freigeist;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0d0ab8;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #3734ea;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #00178a;
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-track:active {
    background: #d7d9ef;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  margin: 0;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: auto;

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 94px);
  }
}

.s-alert-box {
  min-width: 300px;
  padding: 0 20px;
  padding-bottom: 15px;
  font-family: Freigeist;
  font-style: normal;
  font-weight: 100;
  border-radius: 10px;

  .typeMessage {
    font-size: 14px;
    line-height: 12px;
  }

  .message {
    font-size: 12px;
    line-height: 10px;
  }
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
