.slider {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;

  .galleryImage {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &.active {
      visibility: visible;
      opacity: 100;
      transition: 1.5s;
    }
  }

  .arrow {
    display: flex;
    position: absolute;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;

    &:after {
      display: flex;
      content: '';
      background-image: url("../../assets/left-arrow.png");
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
      top: 35px;
      width: 36px;
      height: 36px;
      background-color: #FFFFFF;
      border-radius: 50%;

      &:hover {
        transform: scale(1.1);
        transition: .2s;
      }
    }

    &.prev {
      left: 10px;
    }

    &.next {
      right: 10px;
      transform: rotate(180deg);
    }
  }

  .points {
    display: flex;
    position: absolute;
    bottom: 16px;
    justify-content: center;
    align-items: center;
    height: 10px;
    width: 100%;
    right: auto;

    .point {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px #FFFFFF solid;
      margin: 2px;

      &.active {
        background-color: #FFFFFF;
      }
    }

  }
}