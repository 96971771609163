.placesListWrapper {

  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100000;
  height: 60%;
  width: 100%;
  overflow: hidden;

  border-radius: 16px 16px 0 0;
  background-color: #ffffff;

  .caption {
    padding: 26px;
    font-family: Freigeist, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 23px;
    color: rgba(28, 28, 28, 0.66)
  }

  .list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100000;
    width: 35%;
    min-width: 650px;
    height: calc(100% - 52px);
    margin-top: 26px;
    margin-right: 46px;
    overflow: hidden;

    border-radius: 16px;
    background-color: #ffffff;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }
}