.wrapper {
  height: 100%;
  width: 100%;
  font-family: 'Freigeist';

  .Icon-stream {
    width: 19px;
    height: 19px;
    margin: -3px 5px;
    cursor: pointer;
    border: 1px solid #1C1C1C;
    border-radius: 50%;
    background: #FFFFFF;
    color: #1C1C1C;
    padding: 13px;
    box-shadow: 0px 26px 60px rgba(28, 28, 28, 0.7);
  } 

  .Icon-stream:last-child {
    background: #F12121;
    color: #FFFFFF;
  }
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {

  .wrapper {
    background-image: url('./img/block.jpg');
    background-size: cover;
    
    .btn {
      width: 220px;
      height: 45px;
      display: inline-flex; 
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border: 1px solid #BDBDBD;
      border-radius: 500px;
      color: rgba(28, 28, 28, 0.66);
      font-weight: 550;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      cursor: pointer;
    }
    
    .btn:active {
      background: #2B33EF;
      color: #FFFFFF;
    }
    
    .containerStream {
      height: calc(100% - 16px);
      margin: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      overflow: hidden;
      
      .timing {
        position: absolute;
        top: 20px;
        left: 16px;
        line-height: 16px;
        color: #FFFFFF;
      }

      .btn {
        background: #2B33EF;
        color: #FFFFFF;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(28, 28, 28, 0.66);
    }

    .Activities-bar::-webkit-scrollbar {
      width: 0;
    }

    .Activities-bar {
      position: absolute;
      width: 100%;
      height: calc(100% - 95px);
      bottom: 0;
      background: #FFFFFF;
      box-shadow: 0px 36px 80px -26px rgba(28, 28, 28, 0.2);
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;
      
      .blind {
        width: 35px;
        height: 6px;
        background: #1C1C1C;
        opacity: 0.26;
        border-radius: 16px;
        margin: 20px auto;
      }

      span {
        margin: 0 16px;
        font-size: 20px;
        line-height: 22px;
        color: #1C1C1C;
      }

      .ActiveClose {
        position: absolute;
        right: 50px;
        top: 42px;
        width: 25px;
        height: 25px;
      }

      .activites-item {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 10px 0;
        height: 100%;
        overflow: auto;
        
        div {
          margin: 8px;
        }
      }
    }

    .chat-bar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 406px;
  
      .message-input {
        position: fixed;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        height: 100%;
        overflow-y: scroll;
      }
  
      .message-item {
        display: flex;
        padding: 10px 13px;
      }
  
     .photo {
        background-image: url('./img/userIcon.png');
        width: 52px;
        height: 52px;
        margin-right: 10px;
      }
  
      .message {
        flex-basis: 73%;
        
        p {
          margin: 5px 0;
        }
  
        span {
          font-style: normal;
          font-weight: 550;
          font-size: 14px;
          line-height: 14px;
          color: rgba(28, 28, 28, 0.66);
        }
      }
  
      .message-line {
        background: #ECEDFF;
        border-radius: 10px 10px 0px 0px;
        position: absolute;
        bottom: 171px;
        
        textarea {
          display: block;
          width: 374px;
          height: 56px;
          background: #FFFFFF;
          border: 1px solid #BDBDBD;
          border-radius: 20px;
          margin: 10px 10px;
          resize: none;
          overflow: hidden;
          font-size: 14px;
          line-height: 15px;
          outline: none;
        }

        .send-message {
          width: 34px;
          height: 34px;
          position: absolute;
          right: 25px;
          top: 24px;
          color: #2B33EF;
        }
      }
  
      header {
        height: 74px;
        display: flex;
        align-items: center;
        border: 1px solid #BDBDBD;
        border-radius: 16px 16px 0px 0px;
        background-color: #FFFFFF;
  
      }
  
      span {
        margin: 0 16px;
        font-size: 20px;
        line-height: 22px;
        color: #1C1C1C;
      }
    }
  }

  .fullScreen {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: -94px;
  }
}




/* Medium devices (desktops, 992px and up) 
@media (min-width: 992px) {
  
}



 Large devices (large desktops, 1200px and up) 
@media (min-width: 1200px) {

}
*/