.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  min-height: 54px;
  max-height: 54px;
  padding: 0px 35px;
  border-radius: 26px;
  border: 1px solid;
  cursor: pointer;

  font-family: Freigeist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.secondary {
    background-color: #FFFFFF;
    border-color: #BDBDBD;
    color: #1C1C1C;
  }

  &.light {
    background-color: #FFFFFF;
    border-color: #2B33EF;
    color: #1C1C1C;
  }

  &.main {
    background-color: #2B33EF;
    border-color: #2B33EF;
    color: #FFFFFF;
  }

  &.disabled {
    color: #BDBDBD;
    background-color: #e5e4e4;
    border-color: #BDBDBD;
    cursor: not-allowed;
  }

  &:hover {
    filter: saturate(130%);
  }

}